/* SIR Business Center CSS tnx to https://html5boilerplate.com/ */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

:root {
  --font-main: 'Roboto', sans-serif;
  --font-condensed: 'Roboto Condensed', sans-serif;
  --sir-lime: #c4d601;
  --sir-blue: #2c576d;
  --sir-header-blue: #026D9D;
  --sir-header-blue-light: #DAE7EF;
  --sir-gray-dark: #707070;
  --sir-gray-light: #ebebeb;
  --sir-green-dark: #00757e; 
  --sir-green-light: #00757e;
  --sir-black: #333333;
  --sir-white: #F6F6F5;
}

html {
  color: var(--sir-black);
  font-size: 1rem;
  line-height: 1.4;
  font-family: var(--font-main);
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: var(--sir-white);
  text-shadow: none;
}

::selection {
  background: var(--sir-white);
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

   body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }

  .disable-scroll{
    overflow: hidden;
  }

   header, content, footer { width: 1366px; }

   /*=============
    Header Styles
    =============*/

   header{
     position: relative;
   }

   header .search-form {
      border: 1px solid var(--sir-black);
  }

  .form-group{
    margin-bottom: 0;
  }

  .has-feedback{
    position: relative;
  }

  .search-form .form-control {
    height: 48px;
    border: none;
    font-size: 16px;
    padding-right: 78px;
  } 

  .search-form .search-btn {
    height: 48px;
    background: 0 0;
    width: 55px;
    border: none;
    pointer-events: auto;
    cursor: pointer;
    border-left: 1px solid var(--sir-black);
  }

  .form-control {
    outline: none;
    padding: 0 0 0 20px;
    box-shadow: none;
}

  .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
}

   .logo-box{
     display:flex;
     justify-content: space-around;
     padding: 35px 0 70px 0;
     align-items: center;
   }
   .searchandshop{
     display: flex;
     flex-direction: row;
     align-items: center;
   }

   .shopping-cart{
     cursor: pointer;
     margin-left: 40px;
   }

   .hamburger{
     cursor: pointer;
     position: absolute;
     right: 0;
     top: 70px;
     z-index: 99;
   }

   .hamburger-open--active{
     display: none;
   }

   .hamburger-close{
     display: none;
   }

   .hamburger-close--active{
     display: block;
  }

   .main-menu{
     display: none;
   }

   .main-menu--active{
    display: flex;
    position: absolute;
    z-index: 98;
    width: 100%;
    background-color: white;
    justify-content: center;
    flex-direction: row;
    color: var(--sir-blue);
    padding: 30px 0 146px 0;
  }

   .nav-product-categories{
     padding-right:53px;
   }

   .nav-product-categories-title{
     font-size: 20px;
     color: var(--sir-header-blue);
     text-transform: uppercase;
     margin-bottom: 10px;
     display: block;
   }

   .nav-product-categories ul{
    font-size: 16px;
    list-style: none;
    padding: 0;
    margin: 0;
   }

   .nav-product-categories li, .nav-utility-links li{
     padding: 5px 0;
   }

   .nav-product-categories a, .nav-product-categories a:visited, .nav-product-categories a:active{
    color: var(--sir-blue);
    text-decoration: none;
  }

  .nav-product-categories a:hover{
    color: var(--sir-lime);
  }

   .nav-utility-links{
     padding-left: 53px;
     position: relative;
   }

   .nav-utility-links:before{
      content: "";
      width: 10px;
      height: 100%;
      border-left: 2px solid var(--sir-blue);
      position: absolute;
      left: 0;
      display:flex;
    }

   .nav-utility-links ul{
    font-size: 16px;
    list-style: none;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
   }

   /*==============
    Content Styles
    ==============*/

   .homepage-slider-element{
     height: 380px;
     background-color: var(--sir-green-dark);
     position: relative;
   }

   .breadcrumb{
     font-size: 19px;
     font-weight: 300;
     font-family: var(--font-condensed);
     text-transform: uppercase;
     background-color: var(--sir-blue);
     height: 70px;
     width: 258px;
     box-sizing: border-box;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     padding: 0 0 0 40px;
     color: var(--sir-white);
     position: relative;
   }

   .breadcrumb--frontpage{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
   }
   .breadcrumb::after{
     content:"";
     height: 70px;
     width: 24px;
     background-color: var(--sir-blue);
     clip-path: polygon(0 0, 100% 50%, 100% 50%, 0% 100%);
     position: absolute;
    right: -24px;
   }
   .breadcrumb--frontpage::after{
    position: absolute;
    right: -24px;
   }

   .statement-box{
     font-size: 18px;
     width: 258px;
     height: 310px;
     box-sizing: border-box;
     background-color: var(--sir-green-dark);
     color: var(--sir-white);
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 0 20px 0 40px;
     position: absolute;
     overflow-wrap: break-word;
     top: 70px;
     z-index: 2;
   }

   .slider{
     width: 1109px;
     height: 380px;
     background-color: var(--sir-green-dark);
     position:absolute !important;
     z-index: 1;
     right: 0;
     top: 0;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   .slider::before{
    content: "";
    position: absolute;
    top: 0;
    left: calc(((100vw - (1109px - 258px))/2) * -1);
    width: calc((100vw - (1109px - 258px))/2);
    height: 380px;
    background-color: var(--sir-green-light);
    z-index: 1;
  }
  .slider::after{
    content: "";
    position: absolute;
    top: 0;
    right: calc(((100vw - 1109px)/2) * -1);
    width: calc((100vw - 1109px)/2);
    height: 380px;
    background-color: var(--sir-green-light);
    z-index: 1;
  }

   .slider .splide__slide img{
     width: 1110px;
     height: 380px;
   }
  
   .card-grid{
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     grid-gap: 20px;
     margin-top: 20px;
   }

   .card{
    background-color: var(--sir-blue);
    text-align: center;
    color: var(--sir-white);
    font-size: 20px;
    cursor: pointer;
    height: 340px;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
  .card img{
    width: 100%
  }
  .card-content-box{ 
    position: absolute;
    background-color: #00818ac5;
    width: 100%;
    height: 170px;
  }
  .card-content{
    position: relative;
  }
  .card-content::after{
    content: '';
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-right: white 2px solid;
    border-top: white 2px solid;
  }
  .card-content h2{
    position: absolute;
    font-size: 22px;
    font-weight: 300;
    padding: 0;
    margin: 0;
    top: 47px;
    left: 66px;
    text-transform: uppercase;
  }
  #card-number{
    font-size: 36px;
    font-weight: 500;
    position: absolute;
    top: 32px;
    left: 20px;
  }

  .fortysixty{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 60px;
    color: var(--sir-black);
    font-size: 18px;
    font-weight: 400;
    margin-top: 75px;
  }

 .reverse{
    direction:rtl;
  }

  .reverse > .fortysixty-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
  }
  .fortysixty img{
    width:450px;
    height: 360px;
    object-fit: cover;
  }
  .fortysixty h2{
    font-weight: 300;
    font-size: 36px;
    color: var(--sir-header-blue);
    margin: 15px 0;
    margin-bottom: -15px;
  }
  .fortysixty-button{
    background-color: var(--sir-green-light);
    font-weight: 500;
    font-size: 19px;
    text-transform: uppercase;
    color: var(--sir-white);
    width: fit-content;
    padding: 30px 43px;
    cursor: pointer;
  }
  .affinity-partners-block{
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 60px;
  }
  .affinity-partners-block::before{
    content: '';
    display: flex;
    align-self: center;
    width: 80%;
    box-sizing: border-box;
    border-top: 1px solid var(--sir-black);
    position: absolute;
    top: 0;
  }
  .affinity-partners-block ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .affinity-partners-block h2{
    color: var(--sir-header-blue);  
    font-weight: 300;
    font-size: 36px;
  }
  .affinity-partners-block ul li{
    list-style: none;
  }
  #affinity-slider{
    margin: 20px 0 80px 0;
  }
  .affinity-partners-block .splide__pagination{
    bottom: -4em !important;
  }
  .affinity-partners-block .splide__pagination__page{
    border: 1px solid #ccc !important;
  }
  .affinity-partners-block .fortysixty-button{
    align-self: center;
  }


  /*==============
  Footer Styles
  ==============*/

  footer{
    background-color: var(--sir-green-dark);
    color: var(--sir-white);
    font-weight: 400;
    padding: 36px;
    padding-bottom:0;
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    box-sizing: border-box;
  }
  footer a, footer a:visited, footer a:active{
    color: var(--sir-white);
    text-decoration: none;
  }
  footer a:hover{
    text-decoration: underline;
  }
  footer a::before{
    content: "-";
    box-sizing: border-box;
    margin: 0 10px 0 10px;
  }
  footer a:first-child::before{
    content: "";
    margin: 0;
  }
  .footer-left{
    flex: 1;
  }
  .footer-left div{
    margin-bottom:36px;
  }

  .footer-product-types ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .footer-product-types li{
    font-size: 16px;
  }

/* ====================
  Product Page Styles
  ===================== */

  .product-page-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 386px;
    grid-gap: 20px;
  }
  .product-page-grid h1{font-size:28px; font-weight:300; text-transform: capitalize;  color: var(--sir-white); line-height: 0px; padding:30px; margin: 50px 0 0 0; background-color: var(--sir-green-dark); grid-row:1; grid-column: 1/3;}
  .product-page-grid ul{list-style: none; margin: 0; padding: 0;}
  .ppg-image-box{grid-column: 1; grid-row:2;}
  .ppg-details{grid-column: 2; grid-row:2; }
  .ppg-summary{ border:1px solid var(--sir-green-dark); height: fit-content; grid-column:3; grid-row: 1/3;}
  .ppg-summary h2{ font-size: 22px; color: var(--sir-white); background-color: var(--sir-green-dark); line-height: 0; margin: 0; padding: 20px;}
  .ppg-summary ul:nth-child(2){padding: 20px; position: relative;}
  .ppg-summary ul:nth-child(2) li {font-size: 20px; color: var(--sir-green-dark); }
  .ppg-summary ul:nth-child(2) li span{color: var(--sir-black); }
  .ppg-summary ul:nth-child(2)::after{ content: ""; background-color: var(--sir-green-dark); height: 1px; width: 100%; position:absolute; bottom:0; left: 0;}
  .ppg-summary ul:nth-child(2) span{display: inline-block; width: 130px;}
  .ppg-summary ul:nth-child(3){padding: 20px 20px 0 20px;}
  .ppg-summary ul:nth-child(3) li{ display: block; color: var(--sir-header-blue); padding: 20px; border:2px solid var(--sir-header-blue); text-align: center; text-transform: uppercase; font-size:19px; margin-bottom:10px; cursor: pointer; }
  .ppg-summary ul:nth-child(3) li:hover{ color: var(--sir-white); background-color: var(--sir-green-dark); border-color: var(--sir-green-dark); font-size:19px; margin-bottom:10px; cursor: pointer; }
  .ppg-summary div:nth-child(4){width: 100%; text-align: center; font-size: 18px; text-transform: uppercase; color: var(--sir-green-dark); font-weight: 500;}
  .ppg-summary div:nth-child(5){width: 100%; text-align: center; margin-bottom: 20px; font-size: 16px; color: var(--sir-blue);}
  .ppg-similar-products{grid-column: 1/3; border-bottom:1px solid var(--sir-blue); padding-bottom:30px;}
  .ppg-popular-bundles{grid-column:1/3;}
  .ppg-popular-bundles ul, .ppg-similar-products ul{font-size: 22px; font-weight: 400; color: var(--sir-blue); list-style: none; padding: 0; margin: 0; display:flex; flex-direction: row; justify-content: space-between;}
  .ppg-popular-bundles li, .ppg-similar-products li{ display: flex; flex-direction: column;}
  .ppg-popular-bundles img, .ppg-similar-products img{ object-fit: cover; margin-bottom:10px; }
  .ppg-image-box{width: 390px; position: relative; display: flex; flex-direction: column; align-items: center;}
  .ppg-tag{position:absolute; top:0; left:0; background-color: var(--sir-green-dark); color: var(--sir-white); padding: 10px; font-size: 14px; font-weight: 400; text-transform: uppercase; z-index: 1;}
  .ppg-image-box img{width: 372px; height: 315px; object-fit: cover;}
  .ppg-image-picker {width:372px; margin-top:20px;}
  .ppg-image-picker ul{display: flex; flex-direction: row; justify-content: space-between; margin: 0; padding: 0;}
  .ppg-image-picker img {width:114px; height: 114px; object-fit: cover; background-color: var(--sir-light-blue);}
  .ppg-description{font-size: 22px; color: var(--sir-blue); font-size: 400;}
  .ppg-description ul{  list-style: inside; font-size: 18px; font-weight: 300; color: var(--sir-green-dark);}
  .ppg-item-details h2{font-size: 22px; font-weight: 400; color: var(--sir-blue);}
  .ppg-item-details {font-size: 16p;}
  @media only screen and (max-width: 1366px) {
    .product-page-grid{grid-template-columns: 1fr 386px;}
    .product-page-grid h1{grid-column: 1/3; grid-row: 1/2;}
    .ppg-details{ grid-column: 1/3; grid-row: 3;}
    .ppg-summary{ grid-column: 2/3; grid-row: 2/3;}
    .ppg-similar-products{grid-column: 1/3;}
    .ppg-popular-bundles{grid-column:1/3;}
  }
  @media only screen and (max-width: 834px) {
    .product-page-grid{grid-template-columns: 1fr}
    .product-page-grid h1{grid-column: 1/2; font-size: 22px; margin-top:10px; }
    .ppg-details{ grid-column: 1/2; grid-row:4;}
    .ppg-summary{grid-column: 1/2; grid-row:3;}
    .ppg-similar-products, .ppg-popular-bundles{grid-column: 1/2;}
    .ppg-popular-bundles, .ppg-similar-products{max-width: 834px; overflow: hidden;}
    .ppg-mobile-overflow {max-width: 834px; overflow: scroll;}
    .ppg-popular-bundles li, .ppg-similar-products li{margin-right:40px;}
  }

/* ================
  Category Styles
  =================*/

  .product-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 0 14px;
    margin: 30px 0 0 0;
  }
  @media only screen and (max-width: 834px) {
      .product-grid{padding:0;}
  } 
  .product-grid h2{ box-sizing:border-box; color: var(--sir-white); font-size: 22px; margin: 0; width: 100%; padding: 12px 0  12px 30px; }
  .product-grid h3{ box-sizing:border-box; color: var(--sir-black); background-color: var(--sir-header-blue-light); font-size: 18px; font-weight: 400; margin: 0; width: 100%; padding: 10px 12px; }
  .product-list{ 
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
      order: 1;
      grid-column: 1/2;
  }
  @media only screen and (max-width: 1366px) {
    .product-list{ 
      grid-gap: 14px;
      order:2;
      grid-column: 1/3;
  }
  }
  .product-list-item{
      width: 470px;
      height: 583px;
      background-color: var(--sir-gray-light);
  }
  @media only screen and (max-width: 1366px) {
      .product-list-item{
        width: 390px;
        height: 643px;
    }
  }
  .product-filters{ width: 100%; order: 2; grid-column: 2/4; box-sizing: border-box;}
  @media only screen and (max-width: 1366px) {
      .product-filters{order: -2; grid-column: 1/3;}
  }  
  @media only screen and (max-width: 834px) {
      .product-filters{grid-column: 1/4;}
  }  
  .product-filters ul{
    list-style: none; padding: 0; margin: 18px 0;
  }
  #filter-header-products {cursor: default; background-color: var(--sir-green-light); margin-top:4px;}
  #filter-header-categories{cursor: default; background-color: var(--sir-header-blue);}
  .product-categories, .filter-products-box{display: flex; flex-direction: column; width: 100%;}
  .product-category{width: 100%;}
  .product-categories a{ padding: 10px 0 10px 30px; color: var(--sir-gray-dark); font-size: 18px; text-decoration: none; display: block; box-sizing: border-box;}
  .product-categories a:hover{ color: var(--sir-white); background-color: var(--sir-header-blue); display: block; width: 100%;}
  @media only screen and (max-width: 1366px) {
    #filter-header-products {cursor: pointer;}
    #filter-header-categories{cursor: pointer;}
    .product-categories, .filter-products-box{display: none;} 
    .product-categories--active, .filter-products-box--active{display: flex; flex-direction: column;}
  } 
  .filters > .filter-type:last-child{margin-bottom: 15px;}
  .filter-type{margin:15px 0 0 30px; box-sizing: border-box; display: flex;}
  .filters label{ margin-left:12px; color: var(--sir-blue); font-size: 18px; display: flex; align-items: center; text-transform: capitalize;}

  .filters input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--sir-white);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: var(--sir-black);
    width: 24px;
    height: 24px;
    border: 1px solid var(--sir-gray-dark);
    display: grid;
    place-content: center;
  }
  .filters input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 0ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--sir-gray-dark);
    /* Windows High Contrast Mode */
    background-color: var(--sir-white);
  }
  .filters input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  .filters input[type="checkbox"]:disabled {
    --form-control-color: var(--sir-gray-dark);
    color: var(--sir-gray-dark);
    cursor: not-allowed;
  }

  .product-pagination { order:-1; grid-column: 1/2; display: flex; justify-content: center;}
  @media only screen and (max-width: 1366px) {
      .product-pagination{ order:-1; grid-column: 1/4;}
  }

  .product-pagination{border:1px solid var(--sir-green-dark); padding:10px 15px; display: flex; justify-content: space-between; width: fit-content; justify-self: center;}
  .product-pagination span:nth-child(2){display: flex; justify-content:center; margin-left:40px;}
  @media only screen and (max-width: 834px) {
    .product-pagination {margin-left:0; flex-direction: column; align-items: center; justify-content: center;}
    .product-pagination span:nth-child(2){margin-left:0;}
  }
  .product-pagination ul{list-style: none; display: flex; justify-content: center; padding: 0; margin: 0 10px;}
  .product-pagination li{margin:0 3px}

  .product-list-item{display: grid; grid-template-columns: 1fr 1fr; grid-gap:10px; position: relative; box-sizing: border-box; padding:10px;}
  .pl-tag{ top:0; left:0; font-size: 16px; text-transform: uppercase; position:absolute; background-color: var(--sir-green-light); color: white; padding: 5px 10px;}
  .pl-image{grid-column:1/3; width: 100%; height: 221px; background-color: var(--sir-header-blue); object-fit: border-box;}
  .pl-image img {width: 100%; height: 100%; object-fit: cover;}
  .pl-title{ grid-column:1/3; padding:0 10px; font-size:28px; color: var(--sir-green-light); line-height: 30px; height:80px;}
  .pl-description{grid-column:1/3; padding:0 10px; font-size: 16px; overflow: hidden; box-sizing:border-box; position: relative; margin-bottom:8px; height: 60px; }
  .pl-description:after{content:""; height: 1px; width: calc(100% - 20px); position: absolute; background-color: var(--sir-green-dark); bottom: 0; left: 10px;}
  .pl-price{grid-column:1/2; grid-row:4; width: 242px; padding-left:10px; }
  .pl-member-price, .pl-non-member-price{ width: 100%; text-align: justify; text-justify:inter-word; text-transform: uppercase;  font-size: 18px; font-weight: 500; color: var(--sir-green-light); }
  .pl-buttons{ grid-column:2/3; grid-row:4;}
  .pl-view-button, .pl-customize-button{ text-decoration:none; width: 175px; height: 50px; display: flex; justify-content: center; align-items: center; font-size: 19px; text-transform: uppercase; cursor: pointer; box-sizing: border-box;}
  .pl-view-button{  color: var(--sir-white); background-color: var(--sir-green-dark); border: none; }
  .pl-customize-button{ color:var(--sir-blue); border:2px solid var(--sir-blue); margin-top:8px; }
  .pl-utility-links{width: 100%; margin-top:10px;}
  .pl-save-link, .pl-add-to-cart-link{cursor:pointer; font-size: 16px; text-decoration: underline;}
  .pl-save-link{margin-left: 26px;}
  @media only screen and (max-width: 1366px) {
    .pl-title{font-size: 22px;}
    .pl-description{font-size: 14px; height: auto;}
    .pl-price{grid-column:1/3; grid-row:unset; width: 242px; padding-left:10px; }
    .pl-buttons{ grid-column:1/3; grid-row:unset;}
    .pl-view-button, .pl-customize-button{width:100%;}
  }
  @media only screen and (max-width: 834px) {
    .product-list{grid-template-columns: 1fr;}
  }

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   Media queries breakpoints
   ========================================================================== */

@media only screen and (max-width: 1366px) {
  /* Style adjustments for viewports that meet the condition */

  header, content, footer { width: 834px; }

  .logo-box{
    flex-wrap: wrap;
    justify-content: center;
  }

  .logo{
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 40px;
    width: 80%;
  }

  .search{
    margin-right: 40px;
  }

  .hamburger{
    top: 0;
  }

  .main-menu{
    top: 0;
    padding: 80px 0 60px 0;
  }

  .breadcrumb{
    position: relative;
  }

  .homepage-slider-element{
    width: 834px;
    height: 400px;
    background-color: white;
  }

  .slider{
    width: 834px;
    height: 286px;
    margin-top: 100px;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  .slider::before{
    left: calc(((100vw - 834px)/2) * -1);
    width: calc((100vw - 834px)/2);
    height: 286px;
  }
  .slider::after{
    right: calc(((100vw - 834px)/2) * -1);
    width: calc((100vw - 834px)/2);
    height: 286px;
  }
  .slider .splide__slide img{
    width: 834px;
    height: 286px;
  }

  .statement-box{
    display: none;
  }

  .card-grid{
    grid-template-columns: repeat(2, 1fr);
  }

  .card{
    height: 312px;
  }

  .affinity-partners-block .splide__slide img{
    width: 180px;
    height: 90px;
  }

  footer{
    flex-direction: column-reverse;
  }

  .footer-product-types{
    margin-bottom: 40px;
  }

}

@media only screen and (max-width: 834px) {
  header, content, footer { width: 390px; }

  .logo img{ width: 90%; }

  .logo-box{ padding-bottom: 40px;}

  .search {
    margin-right: 20px;
  }

  .main-menu--active{
    flex-direction: column;
    padding: 80px 10px 60px 30px;
    box-sizing: border-box;
  }

  .nav-utility-links{
    padding: 0;
    margin-top: 40px;
  }

  .nav-utility-links:before{
    border-left:unset;
    border-top: 2px solid var(--sir-black);
    width: 190px;
    position: absolute;
    top: -20px;
  }
  
  .breadcrumb{
    all: unset;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--sir-header-blue );
  }
  .breadcrumb::after{
    display: none;
  }
  .homepage-slider-element{
    width: 390px;
    height: 462px;
    background-color: white;
  }
  .slider{
    width: 390px;
    height: 422px;
    margin-top: 40px;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  .slider::before{
    left: calc(((100vw - 390px)/2) * -1);
    width: calc((100vw - 390px)/2);
    height: 422px;
  }
  .slider::after{
    right: calc(((100vw - 390px)/2) * -1);
    width: calc((100vw - 390px)/2);
    height: 422px;
  }
  .slider .splide__slide img{
    width: 390px;
    height: 422px;
  }
  .card{
    height:170px;
    align-items: unset;
  }
  .card-grid{
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
  .card img{
    object-fit: cover;
    object-position: top;
  }
  .fortysixty {
    display: flex;
    grid-template-columns: unset;
    grid-gap: unset;
    color: var(--sir-black);
    font-size: 18px;
    font-weight: 400;
    margin-top: 40px;
    flex-direction: column;
  }
  .fortysixty img{
    width: 390px
  }
  .fortysixty-button{
    width: 100%;
    text-align: center; 
    padding: 30px 0;  
  }
  .affinity-partners-block ul li ul{
    flex-direction: column;
  }
  .affinity-partners-block ul li ul li{
    margin-bottom: 10px;
  }
  .affinity-partners-block .splide__slide img{
    width: 300px;
    height: auto;
  }
  .affinity-partners-block ul li ul {
    flex-direction: column;
    padding: 0;
  }
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}



/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

